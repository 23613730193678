import { LogWarning } from './logging';
//import { IGlobalResult, ILPVResult } from '../Models/IResult';

/* eslint-disable */

function getHHforProperty(name, hhProps) {
    var hh = hhProps.substring(hhProps.indexOf("<" + name));
    hh = hh.substring(hh.indexOf(">") + 1, hh.lastIndexOf("</" + name));
    hh = hh.replace(/<c0>/g, '<span class="highlight">');
    hh = hh.replace(/<\/c0>/g, '</span>');
    hh = hh.replace(/&amp;#229/g, 'å');
    hh = hh.replace(/&amp;nbsp/g, ' ');   
    return hh;
}

export async function getDigest(site) {
    var fetchJson = async (url, options) => (await fetch(url, options)).json();
    const resObj = await fetchJson(site + '/_api/contextinfo', {
        method: 'POST',
        headers: {
            Accept: 'application/json'
        }
        //credentials: 'include'
    });

    return resObj.FormDigestValue;
}

export function getBaseSearchURL() {
    let url = window.location.href;
    if(url.indexOf('localhost') > -1 || url.indexOf('dev19') > -1) {
        return 'https://dev-sok.udir.no'
    } else if(url.indexOf('udir-sok-utv.azurewebsites.net') > -1) {
        return 'https://dev2-sok.udir.no'
    } else if(url.indexOf('dev-sokeresultat.udir.no') > -1) {
        return 'https://dev-sok.udir.no'
    } else if (url.indexOf('udir-sok-test.azurewebsites.net') > -1) {
        return 'https://qaold-sok.udir.no:8080'
    } else if (url.indexOf('qa-sokeresultat.udir.no') > -1) {
        return 'https://qa-sok.udir.no'
    } else if (url.indexOf('udir-sok-prod.azurewebsites.net') > -1) {
        return 'https://sok.udir.no'
    } else if (url.indexOf('https://sokeresultat.udir.no') > -1) {
        return 'https://sok.udir.no'
    } else if (url.indexOf('https://sokeresultat-ny.udir.no') > -1) {
        return 'https://sok-ny.udir.no'
    } else {
        return 'https://sok.udir.no'
    }
}

export async function getInfoMsg(currentPage?: string) {
    try {
        let navigationResult;
        if(window.location.host === "localhost:3000"){
            navigationResult = await fetch(window.location.protocol + "//udir-sok-utv.azurewebsites.net/app/infomessages.json");
        }else if(window.location.host === "dev-sokeresultat.udir.no"){
            navigationResult = await fetch(window.location.protocol + "//dev-sokeresultat.udir.no/app/infomessages.json");
        }else if(window.location.host === "qa-sokeresultat.udir.no"){
            navigationResult = await fetch(window.location.protocol + "//qa-sokeresultat.udir.no/app/infomessages.json");
        }else{ 
            navigationResult = await fetch(window.location.protocol + "//sokeresultat.udir.no/app/infomessages.json");
        }
        const infoMessage = await navigationResult.json();
        return infoMessage;
    } catch(e) {
        LogWarning("Could not get or parse infomessages, using fallbackdata.")
        const infoMessage = "";
        return infoMessage;
    }
}

/* eslint:disable */
export function GetRESTData(data, srchquery:string, parseRefiners) {
    var n:any[] = [];
    var bb:any[] = [];
    var rb:any[] = [];
    var dataResultsCount = 0;
    var dataTotalResults = 0;
    var dataTotalResultsIncludingDuplicates = 0;
    var dataRefiners:any = [];
    var spellingSuggetions = "";
    var srchquerymodified = "";
    var dataPiPageImpression = "";
    var dataPiPageImpressionBlockType = 2;
    var dataSourceId = "";
    var queryText = "";
    if (data.PrimaryQueryResult) {
        if (data.PrimaryQueryResult.RelevantResults) {
            dataResultsCount = data.PrimaryQueryResult.RelevantResults.RowCount;
            dataTotalResults = data.PrimaryQueryResult.RelevantResults.TotalRows;
            dataTotalResultsIncludingDuplicates = data.PrimaryQueryResult.RelevantResults.TotalRowsIncludingDuplicates;
            dataRefiners = parseRefiners(data.PrimaryQueryResult.RefinementResults);
            spellingSuggetions = data.SpellingSuggestion;
            var table = data.PrimaryQueryResult.RelevantResults.Table.Rows;
            var propertiesArray = data.PrimaryQueryResult.RelevantResults.Properties;
            var dataProperties = data.Properties;
            if (dataProperties) {
                const dataPiPageImpressionObject = dataProperties.find(obj => obj.Key === 'piPageImpression');
                if(dataPiPageImpressionObject){
                    dataPiPageImpression = dataPiPageImpressionObject.Value;
                }
                const dataSourceIdObject = dataProperties.find(obj => obj.Key === 'SourceId');
                if(dataSourceIdObject){
                    dataSourceId = dataSourceIdObject.Value;
                }
                const serializedQueryObject = dataProperties.find(obj => obj.Key === 'SerializedQuery');
                if(serializedQueryObject && serializedQueryObject.Value){
                    const regex = /QueryText="([^"]+)"/;
                    const match = serializedQueryObject.Value.match(regex);
                    if (match && match.length > 1) {
                        queryText = match[1]; // Extract the value of the QueryText attribute
                    }
                }
                
            }
            const queryModificationObject = propertiesArray.find(obj => obj.Key === 'QueryModification');
            if (queryModificationObject) {
                // Removing paranthesis from modified query object, and extracting modified query string
                const queryModificationValue = queryModificationObject.Value;
                let modifiedQuery = queryModificationValue.replace(/^\(+/, "");
                if (modifiedQuery.indexOf("(") !== -1){
                    modifiedQuery = modifiedQuery.split("(")[0];
                }
                modifiedQuery = modifiedQuery.trim();

                // Extract the query string from srchquery (REST query string)
                const queryString = srchquery.split('?')[1]; 
                const params = new URLSearchParams(queryString);
                let queryTextValue = params.get("querytext");
                if (queryTextValue && queryTextValue.startsWith("'") && queryTextValue.endsWith("'")) {
                    queryTextValue = queryTextValue.slice(1, -1);
                }
                
                // Setting srchquerymodified if query modification query is not equal to query in search box
                if(queryTextValue){
                    const decodedQueryTextValue = decodeURIComponent(queryTextValue);
                    if(modifiedQuery.toLowerCase() !== decodedQueryTextValue.toLowerCase()){
                        srchquerymodified = modifiedQuery;
                    }
                }
            } 
            table.forEach(function (e) {
                var a:any = {};
                var fagkode = ""; let katalognavn = ""; var periode = ""; var filetype = ""; var title = "";
                let res = e.Cells.forEach(function (e) {
                    switch (e.Key) {
                        case "Title":
                            a.title = e.Value; title = e.Value;
                            a.type = 'standard';
                            break;
                        case "udirtitle":
                            a.udirtitle = e.Value;
                            break;
                        case "Url":
                            let tempUrl = e.Value;
                            tempUrl = tempUrl.replace("?crawler=true", "");
                            a.Url = tempUrl;
                            break;
                        case "status":
                            var status = e.Value;
                            if(status == "Gyldig"){
                                status = "Gjeldende";
                            }else if(status == "Ikke innført"){
                                status = "Kommende";
                            }else if(status == "Gjeldende for noen trinn"){
                                status = "Gjeldende";
                            }
                            a.status = status;
                            break;
                        case "publisertstatus":
                            var publisertstatus = e.Value;
                            if(publisertstatus == "http://data.udir.no/kl06/status_utgaatt"){
                                publisertstatus = "Utgått";
                            }
                            if(publisertstatus == "http://data.udir.no/kl06/status_publisert"){
                                publisertstatus = "Gjeldende";
                            }
                            a.publisertstatus = publisertstatus;
                            break;
                        case "tittelstortdokument":
                            if (e.Value != null && e.Key != null) {
                                a.title = e.Value + " - " + a.title;
                            }
                            break;
                        case "laereplanaarstrinn":
                            if (e.Value != null && e.Key != null)
                                a.title = a.title + " - " + e.Value;
                            break;
                        case "description":
                            a.description = e.Value;
                            break;
                        case "gradvisinnforingdato":
                                a.gradvisinnforingdato = e.Value;
                                break;
                        case "prosessidenavn":
                            a.prosessoverskrifter = [];
                            var modifiedTitle = a.title;
                            var prosessideider = a.prosessideider;
                            var prosessidenavn = e.Value;
                            if (prosessidenavn && prosessideider) {
                                var prosessidenavnArray = prosessidenavn.split(";");
                                var prosessideiderArray = prosessideider.split(";");
                                var queryString = srchquery;
                                var queryTerms = queryString.split(" ");
                                var showSectionTitles = 0;
                                var queryTerm;
                                //var stopWordsArray = ["av", "det", "en", "ei", "er", "et", "for", "og", "på", "til", "ved"];
                                var stopWordsArray = ["det", "for", "hva", "til", "ved"];
                                // Removing query terms that are stopwords or found in title
                                for (var i = 0; i < queryTerms.length; i++) {
                                    queryTerm = queryTerms[i];
                                    queryTerm = queryTerm.toLowerCase();
                                    if (modifiedTitle.toLowerCase().indexOf(queryTerm) > -1) {
                                        queryTerms[i] = "";
                                    }
                                    if (stopWordsArray.indexOf(queryTerm) > -1) {
                                        queryTerms[i] = "";
                                    }
                                    if (queryTerm.length < 3) {
                                        queryTerms[i] = "";
                                    }
                                }

                                // Checking if we have a match in section titles
                                for (var i = 0; i < queryTerms.length; i++) {
                                    queryTerm = queryTerms[i];
                                    if (queryTerm == "") {
                                        continue;
                                    }
                                    if (prosessidenavn.toLowerCase().indexOf(queryTerm.toLowerCase()) > -1 || prosessideider.toLowerCase().indexOf(queryTerm.toLowerCase()) > -1) {
                                        //Log("We have a match for - " + queryTerm);
                                        var navn; var urlid; var hrefUrl;
                                        for (var i = 0; i < prosessidenavnArray.length; i++) {
                                            var navn = prosessidenavnArray[i];
                                            var urlid = prosessideiderArray[i];
                                            if (navn.indexOf(queryTerm) != -1 || urlid.indexOf(queryTerm) != -1) {
                                                hrefUrl = a.Url + "#" + urlid;
                                                a.prosessoverskrifter.push('<a href="' + hrefUrl + '" target="_blank">' + navn + '</a>')
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case "vedleggurl":
                            a.vedlegg = [];
                            var vedleggtittel = a.vedleggtittel;
                            var vedleggurl = e.Value;
                            if (vedleggtittel == null || vedleggurl == null) break;
                            var vedleggtittelArray = vedleggtittel.split(";");
                            var vedleggurlArray = vedleggurl.split(";");
                            var tittel = "";
                            let url = "";
                            for (var i = 0; i < vedleggtittelArray.length; i++) {
                                tittel = vedleggtittelArray[i];
                                url = vedleggurlArray[i]; url = url.toString();
                                if (url == null) { continue; }
                                if (url.indexOf('.doc') == -1 && url.indexOf('.docx') == -1 && url.indexOf('.docx') == -1 && url.indexOf('.xls') == -1 && url.indexOf('.xlsx') == -1 &&
                                    url.indexOf('.pdf') == -1 && url.indexOf('.rtf') == -1 &&
                                    url.indexOf('.odb') == -1 && url.indexOf('.odf') == -1 && url.indexOf('.odg') == -1 && url.indexOf('.odp') == -1 && url.indexOf('.ods') == -1 && url.indexOf('.odt') == -1) { continue; }

                                if (url.indexOf("/Upload/") == 0 || url.indexOf("/PageFiles/") == 0 || url.indexOf("/Filer/") == 0) {
                                    url = "https://www.udir.no" + url;
                                } else if (url.indexOf("/link/") == 0) {
                                    url = "https://www.udir.no" + url;
                                }
                                var punktumIndex = url.lastIndexOf(".");
                                var filtype = url.substring(punktumIndex + 1);
                                if (tittel.length > 0 && filtype.length > 0) {
                                    a.vedlegg.push('<a href="' + url + '" target="_blank">[' + filtype + '] ' + tittel + '</a>')
                                }
                            }
                            //Log(a.vedlegg);
                            break;
                        case "innholdstype":
                            a[e.Key] = e.Value;
                            if (e.Value == "Læreplan stort dokument") {
                                a.laereplanlinks = [];
                                let url = a["Url"];
                                var prefixUrl = url.substring(0, url.lastIndexOf("/"));
                                a.laereplanlinks.push('<a href="' + prefixUrl + '/Formaal">Formål</a>');
                                a.laereplanlinks.push('<a href="' + prefixUrl + '/Grunnleggende_ferdigheter">Grunnleggende ferdigheter</a>');
                                a.laereplanlinks.push('<a href="' + prefixUrl + '/Hovedomraader">Hovedområder</a>');
                                a.laereplanlinks.push('<a href="' + prefixUrl + '/Kompetansemaal">Kompetansemål</a>');
                                a.laereplanlinks.push('<a href="' + prefixUrl + '/Timetall">Timetall</a>');
                                a.laereplanlinks.push('<a href="' + prefixUrl + '/Vurdering">Vurdering</a>');
                            }
                            break;
                        case "sidetype":
                            a[e.Key] = e.Value;
                            break
                        case "laereplankompetansemaalsett":
                            a[e.Key] = e.Value;
                            var value = e.Value;
                            if (value && value != "") {
                                var linksList = value.split(";");
                                a.laereplankompetansemaalsettlinks = [];
                                for (var i = 0; i < linksList.length; i++) {
                                    var link = linksList[i];
                                    var linktekst = link.substring(link.lastIndexOf("/") + 1);
                                    //linktekst = linktekst.replace("-", "Kompetansemål ");
                                    linktekst = linktekst.replace(/-/g, " ")
                                    linktekst = linktekst.replace("kompetansemal etter ", "");
                                    linktekst = linktekst.replace("arstrinn", "årstrinn");
                                    linktekst = linktekst.replace("pabygging", "påbygging");
                                    linktekst = linktekst.replace("niva", "nivå");
                                    linktekst = linktekst.replace("aktivitetslaere", "aktivitetslære");
                                    linktekst = linktekst.replace("feilsoking", "feilsøking");
                                    linktekst = linktekst.replace("opplaering", "opplæring");
                                    linktekst = linktekst.replace("engelskspraklig", "engelskspråklig");
                                    linktekst = linktekst.replace("entreprenorskap", "entreprenørskap");
                                    linktekst = linktekst.replace("bransjelaere", "bransjelære");
                                    linktekst = linktekst.replace("yrkesutoving", "yrkesutøving");
                                    linktekst = linktekst.replace("arssteget", "årssteget");
                                    linktekst = linktekst.replace("miljo", "miljø");
                                    linktekst = linktekst.replace("yrkesutovelse", "yrkesutøvelse");
                                    linktekst = linktekst.replace("vg1", "Vg1").replace("vg2", "Vg2").replace("vg3", "Vg3");
                                    linktekst = linktekst.replace("vg1", "Vg1");
                                    linktekst = linktekst.trim();
                                    linktekst = linktekst.charAt(0).toUpperCase() + linktekst.slice(1);
                                    var titletekst = linktekst;
                                    if (linktekst.length > 24) {
                                        linktekst = linktekst.substring(0, 24) + "...";
                                    }

                                    a.laereplankompetansemaalsettlinks.push('<a href="https://www.udir.no' + link + '" target="_self" class="kmchapter ' + titletekst.replace("Etter ", "").replace(" ", "").replace(".", "").replace("årssteget", "årstrinn").replace("2trinn", "2årstrinn").replace("4trinn", "4årstrinn").replace("7trinn", "7årstrinn").replace("10trinn", "10årstrinn") + '" title="' + titletekst + '">' + linktekst + '</a>');
                                }

                            }
                            break;
                        case "HitHighlightedProperties":
                            a[e.Key] = e.Value;
                            var hhProps = e.Value; 
                            if(hhProps){
                                hhProps = hhProps.toString();
                                if (a["formaal"] != null) {
                                    a.hhteaser = getHHforProperty("formaal", hhProps);
                                } else if (a["description"] != null) {
                                    a.hhteaser = getHHforProperty("description", hhProps);
                                }
                            }
                            break;
                        case "HitHighlightedSummary":
                            a[e.Key] = e.Value;
                            var hhSummary = e.Value;
                            hhSummary = hhSummary.replace(/<c0>/g, '<span class="highlight">');
                            hhSummary = hhSummary.replace(/<\/c0>/g, '</span>');
                            hhSummary = hhSummary.replace(/<ddd\/>/g, '...');
                            a.hhsummary = hhSummary;
                            if (a.hhteaser == null)
                                a.hhteaser = hhSummary;
                            if (a.innholdstype == "Fag")
                                a.hhteaser = "Tilhører <i>" + a.laereplannavn + " (" + a.kode + ")</i>";
                            if (a.innholdstype == "Kompetansemål")
                                a.hhteaser = "Tilhører <i>" + a.laereplannavn + " (" + a.kode + ")</i>";
                            if (a.innholdstype == "Programområde")
                                a.hhteaser = "Tilhører utdanningsprogram <i>" + a.utdanningsprogramnavn + " (" + a.utdanningsprogramkode + ")</i>";
                            break;
                        case "ExCourseCode":
                            a.fagkode = e.Value;
                            break;
                        case "ExCourseType":
                            a.eksamenstype = e.Value;
                            break;
                        case "ExCatalogName":
                            a.katalognavn = e.Value;
                            //a.description = "Tilhører fagkode " + fagkode + ". Periode " + periode + ".";
                            break;
                        case "ExPeriodName":
                            var periode = e.Value;
                            if (periode.indexOf(";") == -1) {
                                periode = periode.split('-')[1] + '-' + periode.split('-')[0];
                                periode = periode.replace('1-', 'Vår ');
                                periode = periode.replace('2-', 'Høst ');
                                periode = periode.replace('Alle-', 'Alle');
                            } else {
                                periode = periode.substring(0, periode.indexOf("-")); //Getting only the year YYYY
                            }
                            a.periode = periode;
                            break;
                        case "ExUrlEksamen":
                            a.url = e.Value;
                            break;
                        case "ExFilename":
                            a.filnavn = e.Value;
                            if (a.title == null || a.title == "notitle") {
                                a.title = e.Value;
                            }
                            break;
                        case "ExFilenameForb":
                            a.filnavnforberedelse = e.Value;
                            break;
                        case "ExFilenameForhaandssensur":
                            a.filnavnforhaandssensur = e.Value;
                            break;
                        case "ExFileExtension":
                            var fileextension = e.Value;
                            if (fileextension != null)
                                a.fileextension = fileextension.substring(1);
                            else
                                a.fileextension = "";
                            break;
                        case "ExFileExtensionForb":
                            var fileextension = e.Value;
                            if (fileextension != null)
                                a.fileextensionforberedelse = fileextension.substring(1);
                            else
                                a.fileextensionforberedelse = "";
                            break;
                        case "ExFileExtensionForhaandssensur":
                            var fileextension = e.Value;
                            if (fileextension != null)
                                a.fileextensionforhaandssensur = fileextension.substring(1);
                            else
                                a.fileextensionforhaandssensur = "";
                            break;
                        case "ExUrl":
                            a.urldoklager = e.Value;
                            break;
                        case "ExUrlForb":
                            a.urlforberedelse = e.Value;
                            break;
                        case "ExUrlForhaandssensur":
                            a.urlforhaandssensur = e.Value;
                            break;
                        case "ExUrlVedlegg":
                            a.urlvedlegg = e.Value;
                            break;
                        case "ExUrlVedleggForb":
                            a.urlvedleggforberedelse = e.Value;
                            break;
                        case "ExMaalform":
                            a.maalform = e.Value;
                            break;
                        case "ExIsPasswordProtected":
                            a.passordbeskyttet = e.Value;
                            break;
                        case "ExIsPasswordProtectedForb":
                            a.passordbeskyttetforberedelse = e.Value;
                            break;
                        case "ExIsPasswordProtectedVedlegg":
                            a.passordbeskyttetvedleggtileksamen = e.Value;
                            break;
                        case "ExIsPasswordProtectedVedleggForb":
                            a.passordbeskyttetvedleggtilforberedelse = e.Value;
                            break;
                        case "ExPassord":
                            a.passord = e.Value;
                            break;
                        case "ExCatalogTypeName":
                            a.katalogtype = e.Value;
                            break;
                        case "ExCourseLaereplanURL":
                            a.laereplanurl = e.Value;
                            break;
                        case "ExCourseLaereplanKode":
                            a.laereplankode = e.Value;
                            break;
                        case "AAMEnabledManagedProperties":
                        case "PartitionId":
                        case "RenderTemplateId":
                        case "UrlZone":
                            break;
                        case "piSearchResultId":
                            a.piSearchResultId = e.Value;
                            a.piPageImpression = dataPiPageImpression;
                            a.piPageImpressionBlockType = dataPiPageImpressionBlockType;
                            a.sourceId = dataSourceId;
                            break;  
                        default:
                            a[e.Key] = e.Value
                    }
                });
                (n as any[]).push(a)
            });
        }
    }

    var bbResults = data.SecondaryQueryResults;
    if (bbResults && bbResults.length > 0) {
        // Processing Best Bets
        if (bbResults[0].SpecialTermResults != null) {
            var tableBB = data.SecondaryQueryResults[0].SpecialTermResults.Results;
            var a:any = {};
            let res = tableBB.forEach(function (e) {
                a.title = e.Title;
                a.url = e.Url
                a.description = e.Description;
                a.visualbestbet = e.IsVisualBestBet.toString();
            });
            bb.push(a);
        }

        //Processing result blocks
        if (bbResults[0].RelevantResults != null) {
            var tableRB = data.SecondaryQueryResults[0].RelevantResults.Table.Rows;
            tableRB.forEach(function (e) {
                var b:any = {};
                var title = "";
                let res = e.Cells.forEach( e => {
                    switch (e.Key) {
                        case "Title":
                            title = e.Value;
                            b.title = title;
                            break;
                        case "udirtitle":
                            b.udirtitle = e.Value;
                            break;
                        case "Url":
                            var url = e.Value;
                            url = url.replace("?crawler=true", "");
                            b.url = url;
                            break;
                        case "ExCourseCode":
                            b.fagkode = e.Value;
                            break;
                        case "ExCourseType":
                            b.eksamenstype = e.Value;
                            break;
                        case "ExUrl":
                            b.urldoklager = e.Value;
                            break;
                        case "ExUrlForb":
                            b.urlforberedelse = e.Value;
                            break;
                        case "ExUrlForhaandssensur":
                            b.urlforhaandssensur = e.Value;
                            break;
                        case "ExFilename":
                            b.filnavn = e.Value;
                            if (title == "") {
                                b.title = e.Value;
                            }
                            break;
                        case "ExFilenameForb":
                            b.filnavnforberedelse = e.Value;
                            break;
                        case "ExFilenameForhaandssensur":
                            b.filnavnforhaandssensur = e.Value;
                            break;
                        case "ExFileExtension":
                            var fileextension = e.Value;
                            if (fileextension != null)
                                b.fileextension = fileextension.substring(1);
                            else
                                b.fileextension = "";
                            break;
                        case "ExFileExtensionForb":
                            var fileextension = e.Value;
                            if (fileextension != null)
                                b.fileextensionforberedelse = fileextension.substring(1);
                            else
                                b.fileextensionforberedelse = "";
                            break;
                        case "ExFileExtensionForhaandssensur":
                            var fileextension = e.Value;
                            if (fileextension != null)
                                b.fileextensionforhaandssensur = fileextension.substring(1);
                            else
                                b.fileextensionforhaandssensur = "";
                            break;
                        case "ExPeriodName":
                            b.periode = e.Value;
                            break;
                        case "ExIsPasswordProtected":
                            b.passordbeskyttet = e.Value;
                            break;
                        case "ExIsPasswordProtectedForb":
                            b.passordbeskyttetforberedelse = e.Value;
                            break;
                        case "ExIsPasswordProtectedVedlegg":
                            b.passordbeskyttetvedleggtileksamen = e.Value;
                            break;
                        case "ExIsPasswordProtectedVedleggForb":
                            b.passordbeskyttetvedleggtilforberedelse = e.Value;
                            break;
                        case "ExMaalform":
                            b.maalform = e.Value;
                            break;
                        case "ExCatalogTypeName":
                            b.katalogtype = e.Value;
                            break;
                        case "ExCourseLaereplanURL":
                            b.laereplanurl = e.Value;
                            break;
                        case "ExCourseLaereplanKode":
                            b.laereplankode = e.Value;
                            break;
                        case "ExUrlVedlegg":
                            b.urlvedlegg = e.Value;
                            break;
                        case "ExUrlVedleggForb":
                            b.urlvedleggforberedelse = e.Value;
                            break;
                    }
                });
                rb.push(b)
            });
        }
    }
    return {
        query: srchquery,
        modifiedQuery: srchquerymodified,
        elapsedTime: data.ElapsedTime,
        suggestion: data.suggestions,
        spellingSuggestion: spellingSuggetions,
        //resultLimit: e.resultLimit,
        resultsCount: dataResultsCount,
        totalResults: dataTotalResults,
        totalResultsIncludingDuplicates: dataTotalResultsIncludingDuplicates,
        items: n,
        bestbets: bb,
        resultblocks: rb,
        refiners: dataRefiners,
        piPageImpression: dataPiPageImpression,
        piPageImpressionBlockType: dataPiPageImpressionBlockType,
        sourceId: dataSourceId,
        queryText: queryText
    }
}

export function ConvertStringToSearchToken(str) {
    var encoded = '';
    for (var i = 0; i < str.length; i++) {
        var character = str.charAt(i);
        var encodedChar = encodeURIComponent(character);
        if (character != encodedChar) {
            encoded += encodedChar.replace(/%/g, "").toLowerCase();
        } else {
            encoded += parseInt(character.charCodeAt(0)).toString(16);
        }
    }
    return "\"ǂǂ" + encoded + "\"";
}

export function RemoveSpecialCharsFromQuery(query: string){
    query = query.replace(/\(/g, " ");
    query = query.replace(/\)/g, " ");
    query = query.replace(/&/g, " ");
    //query = query.replace(/:/g, " ");
    query = query.replace(/#/g, " ");
    query = query.replace(/""/g, " ");

    // Removing single quotes - non-phrase searches
    if((query.match(/"/g) || []).length === 1) { query = query.replace(/"/g, " "); }
    
    return query;
}
export function getLanguage(result: any){
    let language_attr = {}
    if(result.language && result.language === 'en') {
        language_attr = {lang: 'en'};
    }else if(result.laereplanengtittel && result.laereplanengtittel === result.laereplannavn) {
        language_attr = {lang: 'en'};
    }else if(result.laereplansmatittel && result.laereplansmatittel === result.laereplannavn) {
        language_attr = {lang: 'sma'}; //Sørsamisk
    }else if(result.laereplansmetittel && result.laereplansmetittel === result.laereplannavn) {
        language_attr = {lang: 'sme'}; //Nordsamisk
    }else if(result.laereplansmjtittel && result.laereplansmjtittel === result.laereplannavn) {
        language_attr = {lang: 'smj'}; //Lulesamisk
    }
    //console.log("LANGUAGE:");
    //console.log(result.language);
    //console.log(language_attr);

    return language_attr;
}